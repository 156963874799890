<template>
  <v-form ref="form" lazy-validation>
    <v-dialog
      v-model="showform"
      max-width="1400px"
      persistent
      transition="dialog-top-transition"
      @keydown.esc="close"
    >
      <v-card>
        <v-card-title>
          <span> Liste des depots </span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" sm="6" md="12">
              <listitems
                :list="depots_list"
                :qDelete="Qdepotdelete"
                :Get_suf="'InvDepot'"
                title="Depots"
                :headers="depots_headers"
                :master="false"
                :showedit="false"
                :add="invent.statut_id == '18'"
                :del="invent.statut_id == '18'"
                :del_disable="depot.nbproduit > 0"
                :key="kdep"
                @open="OpenDepotForm"
                @rowselect="DepotChange"
                @btn_click="NextStep"
                :Total="true"
                :ligne="ligne"
                :laoding="loading"
                @delete="depots_modif"
              >
              </listitems>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="2" :md="invent.nbcompte == 2 ? 4 : 2">
              <v-text-field
                autocomplete="off"
                v-model="depot.date_phase1"
                label="Debut Comptage"
                prepend-icon="mdi-calendar"
                readonly
                dense
                @focus="$event.target.select()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2" md="3" v-if="invent.nbcompte == 2">
              <v-text-field
                autocomplete="off"
                v-model="depot.date_phase2"
                label="Debut 3eme Comptage"
                prepend-icon="mdi-calendar"
                readonly
                dense
                @focus="$event.target.select()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2" md="2">
              <v-text-field
                autocomplete="off"
                v-model="depot.date_phase3"
                label="Debut Explication"
                prepend-icon="mdi-calendar"
                readonly
                dense
                @focus="$event.target.select()"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="2" md="2">
              <v-select
                v-model="depot.equipe1_id"
                :items="equipes"
                :item-text="'label'"
                :item-value="'id'"
                :label="
                  invent.nbcompte == 2
                    ? 'Equipe 1er Comptage'
                    : 'Equipe Comptage'
                "
                readonly
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="2" md="2" v-if="invent.nbcompte == '2'">
              <v-select
                v-model="depot.equipe2_id"
                :items="equipes"
                :item-text="'label'"
                :item-value="'id'"
                label="Equipe 2eme Comptage"
                readonly
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="2" md="3" v-if="invent.nbcompte == '2'">
              <v-select
                v-model="depot.equipe3_id"
                :items="equipes"
                :item-text="'label'"
                :item-value="'id'"
                label="Equipe 3eme Comptage"
                readonly
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="2" md="2">
              <v-autocomplete
                v-model="depot.expl_id"
                item-text="nom"
                :item-value="'id'"
                :items="agents_list"
                label="Responsable"
                readonly
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" @click.stop="close"> Fermer </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
    <template>
      <confirmdialog ref="confirm" />
    </template>
    <invdepotform
      :item="depot"
      :items="depots_list"
      :key="kdepot"
      :showForm="!isDepotClosed"
      @close="closeDepotForm"
      :inv_id="parseInt(depot.id)"
      :nbcompte="invent.nbcompte"
      :alldepots="alldepots"
      :equipes="equipes"
      :agents_list="agents_list"
      :readonly="invent.statut_id != '18'"
      @add="depots_modif"
      @update="depots_modif"
    >
    </invdepotform>
  </v-form>
</template>
<script>
const listitems = () => import("./ListItems.vue");
const confirmdialog = () => import("./ConfirmDialog.vue");
const invdepotform = () => import("./InvDepotForm.vue");
import jsPDF from "jspdf";

import DELETE_INVENT_DEPOT from "../graphql/Inventaire/DELETE_INVENT_DEPOT.gql";
import UPDATE_INVENT_DEPOT from "../graphql/Inventaire/UPDATE_INVENT_DEPOT.gql";
import INV_DEPOT from "../graphql/Inventaire/INV_DEPOT.gql";
export default {
  components: {
    listitems,
    confirmdialog,
    invdepotform,
  },
  props: {
    invent: Object,
    alldepots: Array,
    invent_detail: Array,
    agents_list: Array,
    showform: Boolean,
  },
  data() {
    return {
      loading: false,
      depots_list: [],
      ligne: 0,
      change: 0,
      depots_headers: [
        {
          text: "Depot",
          value: "depot_name",
          slot: "href",
          selected: true,
          totlib: true,
        },
        {
          text: "Statut",
          value: "statut_name",
          selected: true,
        },

        {
          text: "Comptés",
          value: "cmpt1",
          slot: "cur",
          align: "end",
          selected: true,
          total: "cmpt1",
        },
        {
          text: "2eme Comptage",
          value: "cmpt2",
          slot: "cur",
          align: "end",
          selected: true,
          total: "cmpt2",
        },
        {
          text: "Comptage 3",
          value: "cmpt3",
          slot: "cur",
          align: "end",
          selected: true,
          total: "cmpt3",
        },
        {
          text: "Expliqués",
          value: "expl",
          slot: "cur",
          align: "end",
          selected: true,
          total: "expl",
        },
        {
          text: "Inventoriés ",
          value: "nbproduit",
          slot: "cur",
          align: "end",
          selected: true,
          total: "nbproduit",
        },
        {
          text: "Fichier",
          value: "nbfichier",
          slot: "cur",
          align: "end",
          selected: true,
          total: "nbfichier",
        },
        {
          text: "Taux",
          value: "taux",
          slot: "cur",
          align: "end",
          selected: true,
        },
        {
          text: "Phase suivante",
          value: "next",
          slot: "btn",
          selected: true,
        },
      ],
      snackbar: false,
      snackbar_timeout: 2000,
      snackbar_text: "",
      snackbar_color: "primary",
      depot: {},
      kdep: 300,
      kdepot: 400,
      isDepotClosed: true,
    };
  },
  async mounted() {
    if (this.invent.nbcompte == 1) {
      this.depots_headers[3].hiden = true;
      this.depots_headers[4].hiden = true;
      this.kdep++;
    }
    await this.update_depot();
  },
  computed: {
    Qdepotdelete() {
      return DELETE_INVENT_DEPOT;
    },
    equipes() {
      return this.invent.equipes ? this.invent.equipes : [];
    },
  },
  methods: {
    async requette(query, v) {
      let r;
      this.loading = true;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    depots_modif() {
      this.$emit("update");
    },
    async update_depot() {
      if (this.invent.id) {
        let v = {
          date_inv: this.invent.date_inv,
          inv_id: this.invent.id,
        };
        v = await this.requette(INV_DEPOT, v);
        if (v) this.depots_list = v.depotinvsit;
        this.kdep++;
      }
    },
    verif_cmpt(col, libelle) {
      let ok = true;
      let i;
      if (col == "qte3")
        i = this.invent_detail.findIndex(
          (elm) =>
            elm.qte1 != elm.qte2 &&
            elm.qte3 === null &&
            elm.depot_id == this.depot.depot_id
        );
      else
        i = this.invent_detail.findIndex(
          (elm) => elm[col] === null && elm.depot_id == this.depot.depot_id
        );

      if (i >= 0) {
        ok = false;
        this.snackbar_text =
          "manque " + libelle + " pour " + this.invent_detail[i].label;
        this.snackbar_color = "error";
        this.snackbar = true;
        this.$emit("close", i);
      }
      return ok;
    },
    async NextStep(item) {
      this.depot = item;
      let ok = true;
      if (item.statut_id == 1) {
        //verifier la designation des equipes
        if (
          item.equipe1_id == null ||
          (item.equipe2_id == null && this.invent.nbcompte == "2")
        ) {
          ok = false;
          this.snackbar_text =
            "Selectionner la ou les equipes de comptage pour ce depot";
          this.snackbar_color = "error";
          this.snackbar = true;
        }
        if (
          ok &&
          this.invent_detail.findIndex(
            (elm) => elm.qte1 !== null && elm.depot_id == this.depot.depot_id
          ) == -1
        ) {
          ok = false;
          this.snackbar_text = "Aucun comptage saisie pour ce depot";
          this.snackbar_color = "error";
          this.snackbar = true;
        }
        //verifier tous cmpt1
        if (ok) ok = this.verif_cmpt("qte1", "Comptage");
        //verifier tous cmpt2
        if (this.invent.nbcompte == "2" && ok) {
          ok = this.verif_cmpt("qte2", "2eme Comptage");
        }
      } else if (item.statut_id == "2") {
        //verifier la designation de l'equipe
        if (item.equipe3_id == null) {
          ok = false;
          this.snackbar_text =
            "Selectionner l'equipe de 3eme comptage pour ce depot";
          this.snackbar_color = "error";
          this.snackbar = true;
        }

        //verifier tous confirm
        ok = this.verif_cmpt("qte3", "3eme Comptage");
        //verifier tous stk phy
        if (ok) ok = this.verif_cmpt("stock_phy", "Stock Physique");
      }

      if (ok) {
        if (await this.$refs.confirm.open("Inventaire", "Etês-vous sûr ?")) {
          if (this.invent.nbcompte == "2") {
            this.depot.statut_name =
              item.statut_id == "1" ? "3eme Comptage" : "Explication";
            this.depot.statut_code = item.statut_id == "1" ? "B" : "C";
            this.depot.date_phase2 =
              item.statut_id == "1"
                ? this.$store.state.today
                : this.depot.date_phase2;
            this.depot.date_phase3 =
              item.statut_id == "2"
                ? this.$store.state.today
                : this.depot.date_phase3;
            this.depot.next = item.statut_id == "1" ? "C" : null;
            this.depot.statut_id = parseInt(item.statut_id) + 1;
          } else {
            this.depot.statut_name = "Explication";
            this.depot.statut_code = "C";
            this.depot.date_phase3 = this.$store.state.today;
            this.depot.next = null;
            this.depot.statut_id = 3;
          }
          this.$apollo
            .mutate({
              mutation: UPDATE_INVENT_DEPOT,
              variables: {
                invdepot: {
                  id: item.id,
                  statut_id: this.depot.statut_id,
                  date_phase2: this.depot.date_phase2,
                  date_phase3: this.depot.date_phase3,
                  write_uid: this.$store.state.me.id,
                },
              },
            })
            .then(() => {
              this.$emit("update");
            })
            .catch((error) => {
              this.loading = false;
              this.snackbar_text = error.message;
              this.snackbar_color = "error";
              this.snackbar = true;
            });
          this.depots_list.splice(this.depot.index, 1, this.depot);
          this.kdep++;
        }
      }
    },
    OpenDepotForm(item) {
      this.depot = item;
      this.isDepotClosed = false;
      this.kdepot++;
    },
    closeDepotForm(item) {
      this.ligne = item.index;
      this.kdep++;
      this.isDepotClosed = true;
    },
    DepotChange(item) {
      this.depot = item;
    },
    close() {
      this.$emit("close", -1);
    },
    open(item) {
      this.$emit("open", item);
    },
    numberWithSpace: function (x) {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
    },
    sumCol(key) {
      // sum data in give key (property)
      return this.list.reduce((a, b) => a + (b[key] || 0), 0);
    },
    print() {
      const addHeaders = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        doc.setFont("helvetica", "italic");
        doc.setFontSize(8);
        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          var dataimg =
            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIIAAACCCAMAAAC93eDPAAAAgVBMVEX1gh8AAAAxGgZiNAyjVxWTThNSKwpyPQ5sOQ7EaBmDRRHUcRsdDwQDAgDcdRzfdhyJSRHleR3ufh64YhdAIggXDAPRbxqPTBKZURMlFAVEJAkhEQQSCgLLbBoLBgEpFgVXLguvXRY4HgdULAupWhV4QA9LKAlDJAk8IAheMgygVRQAwNKAAAAD2klEQVR4nO2a23aqOhRAV5AIAcIlyE0gikWr/v8HnhXauluP1bbAht2R+cCoIYM1mxsJCYBGo9FoNBrNMPiuW4vpwgfOjhMkhZpGXQqtP+awY3UV7liSbSVPeZy6AYZGDQUJP2bxqLqmpB3HoOEb/+3v+wqiCcZRKIkdRxcFF6L6TSE427vCM+qLAlP5opqxztlVtVWnb+k9SLOtx3dO8KZwLlEhA4i3nGz2Ma0K8aoQkAaYwY+HHd/iz+UGL94am/IQFeTna3mI3yu4B8JPTN1rSPxO4WmjXN3kPLSCen7I4z8KO0mW6esdbl4UWliUKplVT0MpNKdLSxStpJ1CKFcrQiSNXnD3MgJvi/0xoBjKLUlxLAnZQLSpsBfLwhcmYT9XEAdygW8FuKaAHfmIgc9f420cOiRTbc80TbaVBy+kz9XBzIzCoD83gG5UdFnHa3k0hLL3vCTXJs3y+DIyCW+VjdRBMVayfpxJbKU7lgBk0njcx9tCNqMZwGn/hdfAYjteGXyRoV9WLLQX/8e2buDcIKRIjz6JHeJIBsDuo3A9CPx9BTaIQS+FcHoFa3oF+9coWFpBK2iFX6vwNMHa+krB+fsG1wplRr9L3viPw3xD4Wccek3nh2mOq+kV+qznhlLIplfYT68wgymLVvgFCjOYxJ+nV4iSyRWAeZMrgDDttfFtvCEVfiieTK4AxWAK+fJnPA9XETMYmrSCVtAKWmEkhRl8XwgfRxpZgaePI42s0OuzRHhnWsKvAlW3sx1PcR+Du1xPKxejRdIKoDZ/P6O6Unj+PGv9ONKnzGB0nMGCbgYKe62AOFphHgoz2K2dgUI2vQKdXiGfXoHdOi/Scet0yaeYPRQ0/w6C3dmCc3sdJ/oq7N6ey5L031z1s+7EnmgdqpZEqemb9GVdEIc0jVtwHbJkEDd+o3KFuW+q25HpUL9T8HPaZy2FBtWu8ATUJS8lt9SAXVVSzZUDg1SSywTahEgbjlKuofZIIiVfqWPLvOQ8VApVwvm5j0JMFjUTwqtqECeSo8IJooJEcMDnCoskrxVxJFgIhYwhWpIV+HITQLR9DlAhh5QbvYrBknwBLlmCinVCBQx1IilUJaaIPwpcnXw9dLlW0F5WkUu0hTLpY9CEwiYxJEmgprIUFbCmF6iw4o16ib1XgESVla1Kge8wsvWkSgHTN70UTLI0pA+xlMdCFcVFwfXIpiT8g0IsuYE1j22B8uqYqA8bAyhAa1uqQQehvVctnTnYzhsH/0eRW1ZzzrDFOnjj3JV8lNl7FqrG5zqLvRoSWpWe9/nCodFoNBqNRqOZD/8BpLpPM0WrUdMAAAAASUVORK5CYII=";
          doc.addImage(dataimg, "png", 15, 15, 20, 20);
          doc.setFontSize(12);
          doc.text(this.title, 15, 45);
          doc.setFontSize(9);
          doc.text("Date : " + this.$store.state.today, 170, 45);
        }
      };
      const addFooters = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        doc.setFont("helvetica", "italic");
        doc.setFontSize(8);
        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.text(
            "Page " + String(i) + " of " + String(pageCount),
            doc.internal.pageSize.width / 2,
            287,
            {
              align: "center",
            }
          );
        }
      };

      let head = [];
      let columns = [];
      let items = [];
      let foot = [];
      let item;
      //data formating

      for (let index = 0; index < this.list.length; index++) {
        item = {};
        this.listheaders.forEach((elm) => {
          if (elm.value == "no") {
            item[elm.value] = index + 1;
          }
          if (elm.slot == "cur22") {
            item[elm.value] = this.numberWithSpace(
              parseFloat(this.list[index][elm.value]).toFixed(2)
            );
          } else if (elm.slot == "cur" && this.list[index][elm.value]) {
            item[elm.value] = this.numberWithSpace(
              parseFloat(this.list[index][elm.value]).toFixed(2)
            );
          } else if (this.list[index][elm.value])
            item[elm.value] = this.list[index][elm.value];
        });
        items.push(item);
      }

      let columnStyles = {};
      var doc = new jsPDF("p");
      item = {};
      this.listheaders.forEach((element) => {
        head.push({
          content: element.text,
          styles: {
            halign:
              element.align == "end"
                ? "right"
                : element.align == "center"
                ? "center"
                : "left",
          },
        });

        if (element.totlib) {
          item[element.value] = "Totals";
        } else if (element.total != null) {
          if (this.sumCol(element.total))
            item[element.value] = this.numberWithSpace(
              this.sumCol(element.total).toFixed(2)
            );
          else
            item[element.value] = this.numberWithSpace(
              element.total.toFixed(2)
            );
        }

        columns.push({ dataKey: element.value });
        columnStyles[element.value] = {
          halign:
            element.align == "end"
              ? "right"
              : element.align == "center"
              ? "center"
              : "left",
        };
      });

      if (this.Total) foot.push(item);

      doc.autoTable({
        margin: { top: 50 },
        headStyles: {
          valign: "middle",
          fillColor: [128, 128, 128],
        },
        columnStyles: columnStyles,
        head: [head],
        body: items,
        columns: columns,
        foot: foot,
        footStyles: {
          halign: "right",
          fillColor: [128, 128, 128],
        },
        showHead: "everyPage",
        showFoot: "everyPage",
      });
      addHeaders(doc);
      addFooters(doc);
      doc.setProperties({ title: this.title });
      doc.output("pdfobjectnewwindow");
    },
  },
};
</script>
